<template>
  <body style="position: relative;">
  <div id="wrap">
		<section>
			<headerLayout v-bind:activeNum="active" v-on:updateActiveNum="updateNum"></headerLayout>
		</section>
    <main>
        <section class="sub-visual">
            <div class="innWrap">
                <div class="txt">
                    <h2>GiGA office</h2>
                    <p>GiGA 네트워크는 물론 랙호스팅, 운용대행까지 ICT Total Care 서비스</p>
                </div>
                <div class="location">
                    <ul>
                        <li><a>PRODUCTS</a></li>
                        <li><a>GiGAoffice</a></li>
                        <li><span>서비스소개</span></li>
                    </ul>
                </div>
            </div>
        </section>

        <section class="sub-section svcInfo-giga bgGray">
            <dl class="innWrap flex sLayout01">
                <dt>
                    <h3 class="sLtitle">서비스 소개</h3>
                    <p>
                        기업, 공공고객을 위한 "기업 전용 시설"을 기반으로 KT 국사에서<br />
                        고객의 사업장까지 광케이블로 연결하여<br />
                        인터넷 접속 뿐만 아니라, 고객망의 특성에 맞게 보안/사설망/ICT<br />
                        Total Care 서비스를 제공합니다.
                    </p>
                    <a @click="$router.push('/goSupCns000')" class="btn-md arrow btn-primary bdrds50 mgr30">컨설팅 신청</a>
                    <a @click="$router.push('/goSvcOrd000')" class="btn-md arrow btn-line02 bdrds50">온라인 청약</a>
                </dt>
                <dd>
                    <div class="imgWrap">
                        <div class="img"></div>
                    </div>
                </dd>
            </dl>
        </section>

        <section class="sub-section DetailInfo">
            <div class="innWrap scrWrap flex">
                <div class="scrNav">
                    <ul>
                        <li>
                            <span class="line" v-bind:class="{active: active === 1}"></span><a href="" @click="moveTab(1)" v-smooth-scroll v-bind:class="{active: active === 1}">상세정보</a>
                        </li>
                        <li>
                            <span class="line" v-bind:class="{active: active === 2}"></span><a href="" @click="moveTab(2)" v-smooth-scroll v-bind:class="{active: active === 2}">요금안내</a>
                        </li>
                        <li>
                            <span class="line" v-bind:class="{active: active === 3}"></span><a href="" @click="moveTab(3)" v-smooth-scroll v-bind:class="{active: active === 3}">이용안내</a>
                        </li>
                    </ul>
                </div>

                <div class="scrCon">
                    <div id="section-01" class="giga-section-01 Sbdb1px">
                        <h3 class="sCtitle">상세정보</h3>
                        <div class="con01">
                            <h4>1. 기본서비스</h4>

                            <table class="tbl_type03">
                                <colgroup>
                                    <col style="width: 19%">
                                    <col style="width: 27%">
                                    <col style="width: 27%">
                                    <col style="width: 27%">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>구분</th>
                                        <th>GiGA office Standard</th>
                                        <th>GiGA office Compact</th>
                                        <th>GiGA office Simple</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="agl">LAN</td>
                                        <td>1Gbps (Optical L/L)</td>
                                        <td>0.5Gbps (Optical L/L)</td>
                                        <td>0.2Gbps (Optical L/L)</td>
                                    </tr>
                                    <tr>
                                        <td class="agl">인터넷 접속</td>
                                        <td colspan="3">0.1Gbps</td>
                                    </tr>
                                    <tr>
                                        <td class="agl">Rack hosting</td>
                                        <td class="line2">1/4Rack씩 추가가능<br />최대 1Rack 사용 가능</td>
                                        <td class="line2">1/4Rack씩 추가가능<br />최대 1/2Rack 사용 가능</td>
                                        <td>미제공</td>
                                    </tr>
                                    <tr>
                                        <td class="agl">기본IP<sub>(IP추가 가능)</sub></td>
                                        <td>16개(실사용 13개)</td>
                                        <td>8개(실사용 5개)</td>
                                        <td>4개(실사용 1개)</td>
                                    </tr>
                                    <tr>
                                        <td class="agl">F/W, DHCP, NAT</td>
                                        <td colspan="3">제공</td>
                                    </tr>
                                    <tr>
                                        <td class="agl">관리용 포털 계정</td>
                                        <td colspan="3">제공</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="con02">
                            <h4>2. 부가서비스</h4>

                            <table class="tbl_type03">
                                <colgroup>
                                    <col style="width: 19%">
                                    <col style="">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>구분</th>
                                        <th>구성</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="agl">Rack Hosting</td>
                                        <td class="agl">¼ Rack 단위 공간 제공</td>
                                    </tr>
                                    <tr>
                                        <td class="agl">LAN2LAN</td>
                                        <td class="agl">본-지사간 인트라넷을 MPLS로 구성하여 보안성이 우수한 서비스</td>
                                    </tr>
                                    <tr>
                                        <td class="agl">LAN2Cloud</td>
                                        <td class="agl">
                                            Cloud Center에 GiGA LAN으로 고객 전용 사설 네트워크를 구성하여 데이터를 빠르고 안전하게 전송하는 서비스
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="agl">운용대행 서비스</td>
                                        <td class="agl">
                                            GiGA office 센터로 이전한 고객사의 서버 및 네트워크 장비를 전문 IT인력이 관제/운용/유지보수 하는 서비스
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="con03">
                            <h4>3. 부가서비스 소개 자료</h4>
                            <div class="sLayouBox">
                                <div class="left">
                                    <div class="boxTit">LAN2LAN</div>
                                    <div class="boxCon">
                                        <div class="transformX50">
                                            <img src="@/assets/images/svc_03_02.png" alt="LAN2LAN">
                                            <a href="https://flexon.kt.co.kr/gigaofficefile/KT0002.pdf" target="_blank" class="btn-lg down btn-primary">다운로드</a>
                                        </div>

                                    </div>
                                </div>
                                <div class="right">
                                    <div class="boxTit">운용대행 서비스</div>
                                    <div class="boxCon">
                                        <div class="transformX50">
                                            <img src="@/assets/images/svc_03_03.png" alt="LAN2LAN">
                                            <a href="https://flexon.kt.co.kr/gigaofficefile/KT0004.pdf" target="_blank" class="btn-lg down btn-primary">다운로드</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="con04">
                            <h4>4. 특장점 및 기대효과</h4>

                            <p class="pdl30">
                                국내 유일 KT GiGA office 신청으로 고가의 인프라 구축/운영비를 절감할 뿐만 아니라 장비로 인한 상면/발열/소음 없는 쾌적한 업무환경을 구축할 수 있습니다.
                            </p>
                            <div class="sLayouIcon">
                                <div>
                                    <p class="roundTxt">최대 100 Gbps 속도</p>
                                    <dl>
                                        <dt>10배 빠른  GIGA 네트워크</dt>
                                        <dd>기업전용망을 통해 빠르고<br />쾌적한 업무환경 제공</dd>
                                    </dl>
                                </div>
                                <div>
                                    <p class="roundTxt">안전하고 편리한  ICT 통합 운영</p>
                                    <dl>
                                        <dt>손쉬운 통신 자원 관리</dt>
                                        <dd>보안서비스와 관리자용 포털을<br />통한 편의성 제공</dd>
                                    </dl>
                                </div>
                               <div>
                                    <p class="roundTxt">운영비용 절감 및 관리 용이</p>
                                    <dl>
                                        <dt>부대비용 절감</dt>
                                        <dd>장비의  KT국사 이전으로<br />상면임대료/전기료 절감</dd>
                                    </dl>
                               </div>
                            </div>
                        </div>

                    </div>

                    <div id="section-02" class="giga-section-02 Sbdb1px">
                        <h3 class="sCtitle">요금안내</h3>

                        <div class="con01">
                            <h4>1. 회선서비스 <span>※ 표 안의 금액은 부가세가 포함된 실제 지불 금액입니다. (원/월)</span></h4>
                            <table class="tbl_type03">
                                <colgroup>
                                    <col style="width: 16%">
                                    <col style="width: 16%">
                                    <col style="width: 17%">
                                    <col style="width: 17%">
                                    <col style="width: 17%">
                                    <col style="width: 17%">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>구분</th>
                                        <th>무약정</th>
                                        <th>2년 이상</th>
                                        <th>3년 이상</th>
                                        <th>4년 이상</th>
                                        <th>5년 이상</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colspan="6" class="noList">
                                            요금 견적은 로그인 후 확인 <br />※ 약관가는 브로슈어 참조
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <span class="asterisk">* 가입설치비 및 장치사용료는 2년 이상 계약 시 면제. 단, 2년 이내 해지 시 가입설치비 전액 및 장비설치료 할인반환금이 위약금으로 부과</span>
                        </div>

                        <div class="con02">
                            <h4>2. 추가IP 서비스 요금</h4>
                            <table class="tbl_type03">
                                <colgroup>
                                    <col style="width: 16%">
                                    <col style="">
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th>구분</th>
                                        <th>IP개수(약정무관)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colspan="2" class="noList">
                                            요금 견적은 로그인 후 확인 <br />※ 약관가는 브로슈어 참조
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="con03">
                            <h4>3. 운용대행 서비스 요금 <span>※ 표 안의 금액은 부가세가 포함된 실제 지불 금액입니다. (원/월)</span></h4>
                            <table class="tbl_type03">
                                <thead>
                                    <tr>
                                        <th>구분</th>
                                        <th>제공 서비스</th>
                                        <th>표준요금(서버당)</th>
                                        <th>서비스 제공기준 (SLA)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colspan="4" class="noList">
                                            요금 견적은 로그인 후 확인 <br />※ 약관가는 브로슈어 참조
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="con04">
                            <h4>4. 같이 쓰면 좋은 상품</h4>
                            <div class="sLayouIcon">
                                <dl>
                                    <dt>VPN</dt>
                                    <dd>기업전용망을 통해 빠르고 쾌적한<br />업무환경 제공</dd>
                                </dl>
                                <dl>
                                    <dt>Managed 보안</dt>
                                    <dd>보안서비스와 관리자용 포털을 통한<br />편의성 제공</dd>
                                </dl>
                                <dl>
                                    <dt>기업인터넷전화</dt>
                                    <dd>장비의  KT국사 이전으로 상면임대료/<br />전기료 절감</dd>
                                </dl>
                            </div>
                        </div>
                    </div>

                    <div id="section-03" class="giga-section-03">
                        <h3 class="sCtitle">이용안내</h3>
                        <h4>신청 및 문의</h4>
                        <div class="dotList indent">
                            <ul>
                                <li>전화상담신청(서비스문의, 신청 및 해지) : 1588-0114</li>
                                <li>KREN 신청 : 교육전산망운영본부 (http://www.kren.ne.kr)</li>
                                <li>고장(장애)접수 : 1588-0114</li>
                                <li>1:1 문의하기</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
    <section>
      <footerLayout></footerLayout>
    </section>
    </div>
  </body>
</template>
<script>
import HeaderLayout from './common/Header.vue'
import FooterLayout from './common/Footer.vue'
import common from '@/assets/js/common.js'

export default {
  components: {
    HeaderLayout,
    FooterLayout
  },
  data () {
    return {
        active: 1,
				sectionNum: [
					'section-01',
					'section-02',
					'section-03'
				],
    }
  },
  created: function () {

  },
  mounted: function () {
  },
  methods: {
		updateNum(num) {
			this.active = num
		},
    moveTab(num) {
      var location = common.getOffset(document.getElementById(this.sectionNum[num-1])).top - 30
      window.scrollTo({top:location, behavior:'smooth'});
    }
  }
}
</script>